import * as React from "react";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import ImageComponent from "../components/ImageComponent";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Link} from "gatsby";

const MissionsPage = () => {

    return (
        <DefaultLayoutComponent activeCategory="About">
            <GatsbySeo
                title='La Compta de Papa'
                description='Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.'
                canonical='https://lacomptadepapa.fr/missions'
                openGraph={{
                    url: 'https://lacomptadepapa.fr/missions',
                    title: 'La Compta de Papa',
                    description: 'Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.',
                }}
            />
            <main className={"pt-5 mt-lg-4 mt-5"}>
                <div className="mt-3 mt-lg-0 slider-area hero-bg-color hero-height2">
                    <div className="slider-active">
                        <div className="single-slider">
                            <div className="slider-cap-wrapper">
                                <div className="hero-caption hero-caption2">
                                    <h2 className={"text-center text-sm-start mb-0"} data-animation="fadeInUp" data-delay=".2s">Nos Missions</h2>
                                </div>
                                <div className="hero-img hero-img2 position-relative" data-animation="fadeInRight" data-transition-duration="5s">
                                    <ImageComponent imageName={"hero3.jpg"} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pricing-card-area bg-white fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-6">
                                <div className="section-tittle mb-15">
                                    <h2>Mission comptable et fiscalité</h2>
                                    <ul>
                                        <li>Gestion de votre comptabilité</li>
                                        <li>Prise en charge de vos obligations légales : <br/> comptes annuels, déclarations fiscales, révision et pointage de vos comptes, attestation de vos comptes, saisie de vos pièces comptables.</li>
                                        <li>Analyse de votre bilan</li>
                                        <li>Prévisionnel</li>
                                        <li>Evaluation, transmission d’entreprises</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="offset-lg-1 col-xl-4 col-lg-5 col-md-6 col-sm-8">
                                <div className="card-wrapper">
                                    <ImageComponent imageName={"mission-compta.jpg"} alt={'Comptabilité et fiscalité'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pricing-card-area fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="d-none d-sm-inline-flex offset-lg-1 col-xl-4 col-lg-5 col-md-6 col-sm-8 p-3 mb-4">
                                <ImageComponent imageName={"digital.png"} alt={'Comptabilité et fiscalité'}/>
                            </div>
                            <div className="offset-xl-1 col-xl-6 col-lg-6 col-md-6">
                                <div className="section-tittle mb-15">
                                    <h2>Offre numérique</h2>
                                    <p>Vous souhaitez gérer facilement votre société et la piloter de manière intuitive ? Nous proposons à nos clients, la solution MEG, une solution collaborative, sécurisée et facile d’utilisation, ses différents modules sauront vous séduire.</p>
                                    <ul>
                                        <li>Vous souhaitez améliorer votre gestion d’entreprise ?</li>
                                        <li>Regrouper les tâches administratives dans un seul outil ?</li>
                                        <li>Pouvoir facturer simplement ?</li>
                                        <li>Suivre plus facilement vos échéances clients ?</li>
                                        <li>Gérer facilement vos notes de frais ?</li>
                                        <li>Pouvoir relancer les impayés ?</li>
                                        <li>Suivre l’état de votre caisse rapidement ?</li>
                                        <li>Avoir un tableau de bord synthétique ?</li>
                                    </ul>
                                    <p>D’autres solutions peuvent être mise en place, venez en parler avec nous ! </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="brand-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 col-md-10">
                                <div className="section-tittle section-tittle2 text-center d-flex justify-content-around">
                                    <h2 className={'m-0'}>N'hésitez pas, vous pouvez <Link to={"/nous-contacter"} className={'text-decoration-underline text-warning'}>nous contacter</Link> !</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pricing-card-area bg-white fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="offset-xl-1 col-xl-6 col-lg-6 col-md-6">
                                <div className="section-tittle mb-15">
                                    <h2>Ressources Humaines et paie</h2>
                                    <p>Le droit du travail français est complexe et des évolutions sont apportées régulièrement. Cela peut donc devenir pour les chefs d’entreprises une charge lourde et récurrente nécessitant beaucoup de temps.</p>
                                    <p>Nous proposons donc : </p>
                                    <ul>
                                        <li>Etablissement des bulletins de salaire</li>
                                        <li>Déclarations et paiements des charges sociales</li>
                                        <li>Elaboration des contrats de travail, formalités d'embauche, gestion des fins de contrats</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="offset-lg-1 col-xl-4 col-lg-5 col-md-6 col-sm-8 p-3 mt-4">
                                <ImageComponent imageName={"money.jpg"} alt={'Comptabilité et fiscalité'}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pricing-card-area fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="d-none d-sm-inline-flex offset-lg-1 col-xl-4 col-lg-5 col-md-6 col-sm-8 p-4 mb-5">
                                <ImageComponent imageName={"law.png"} alt={'Comptabilité et fiscalité'}/>
                            </div>
                            <div className="offset-xl-1 col-xl-6 col-lg-6 col-md-6">
                                <div className="section-tittle mb-15">
                                    <h2>Missions juridiques</h2>
                                    <p>Une entreprise est amenée à évoluer tout au long de sa vie et nous sommes là pour vous aider, vous soutenir et vous aiguiller dans vos démarches juridiques telles que :</p>
                                    <ul>
                                        <li>Création d'entreprise</li>
                                        <li>Choix primaire ou évolution du statut de la société</li>
                                        <li>Rédaction d'actes juridiques</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pricing-card-area bg-white fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="offset-xl-1 col-xl-6 col-lg-6 col-md-6">
                                <div className="section-tittle mb-15">
                                    <h2>Création d'entreprise</h2>
                                    <p>Des envies de création mais la lourdeur de l’administratif et des démarches pour concrétiser vos projets vous bloquent ?</p>
                                    <p>Nous vous proposons de vous aider et de vous accompagner de l’étincelle de votre projet jusqu’à son aboutissement :</p>
                                    <ul>
                                        <li>Analyse du marché</li>
                                        <li>Etude des statuts juridiques et élaboration des actes nécessaires</li>
                                        <li>Recherche de fonds</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="offset-lg-1 col-xl-4 col-lg-5 col-md-6 col-sm-8 p-4 mt-5">
                                <ImageComponent imageName={"business.png"} alt={'Comptabilité et fiscalité'}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wantToWork-area w-padding2">
                    <div className="container border-bottoms pb-50">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-8 col-lg-9 col-md-8">
                                <div className="wants-wrapper">
                                    <div className="wantToWork-caption wantToWork-caption2">
                                        <h2>Des envies de création ?</h2>
                                        <p>Nous vous proposons de vous aider et de vous accompagner de l’étincelle de votre projet jusqu’à son aboutissement.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 d-flex d-sm-block justify-content-center ">
                                <Link to="/nous-contacter" className="btn_1 wantToWork-btn brand-btn f-right">Nous contacter</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DefaultLayoutComponent>
    )
};

export default MissionsPage;
